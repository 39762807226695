
<template>
    <div>
      <div class="xcxkf"></div>
      <el-button type="primary" @click="adddh">添加</el-button>
      <el-button type="primary" @click="cleardh">清空</el-button>
      <div class="xcxkf"></div>
      <el-table	:data="listdh">
        <el-table-column  label="类别"  width="300">
          <template slot-scope="scope">
            <el-cascader
                :value="scope.row.type"
                :options="navigation_type_options"
                :props="{ expandTrigger: 'hover' }"
                @change="(selected) => navigationTypeChangeHandler(selected, scope)"
            ></el-cascader>
          </template>
        </el-table-column>
        <el-table-column  label="顶部左上角标题"  width="200">
          <template slot-scope="scope">
            <el-input v-model="scope.row.title" placeholder="标题为空为本来的默认值"	clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column  label="底部名字"  width="200">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name" placeholder="不能为空"	clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column  label="排序"	width="150">
          <template slot-scope="scope"	>
            <el-button type="primary" @click="row_updh(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>
            <el-button type="primary" @click="row_downdh(scope.$index)" :disabled="scope.$index==listdh.length-1"	size="mini">向下</el-button>
          </template>
        </el-table-column>
        <el-table-column  label="操作"  width="80">
          <template slot-scope="scope"	>
            <el-button type="primary" @click="delete_rowdh(scope.$index)" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button :type="loadingdh? 'info': 'primary'" icon="el-icon-s-operation" plain round
                 @click="bottomNavigation()" class="buttonUseDeploy" :loading="loadingdh">
        {{ loadingdh? '加载中' : '执 行 底 部 导 航 栏 配 置' }}
      </el-button>
    </div>
</template>

<script>
import navigationTypeOptions from "@/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/constant/navigation-type-options";

export default {
  props: [
    'checkedCities'
  ],
  data() {
    return {
      navigation_type_options: navigationTypeOptions,
      listdh:[],
      listdh_max_length: 5,
      loadingdh:false,
    }
  },
  methods: {
    // 查询上一次数据接口
    async getBottomNavigation() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getBottomNavigation",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        // 解码
        this.listdh = JSON.parse(decodeURI(res.message));
      }
    },
    // 修改数据接口
    async bottomNavigation() {
      this.loadingdh = true; //开启加载中
      if (this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loadingdh = false; //关闭加载中
        return;
      }
      // console.log("this.listdh", this.listdh)
      let navigationBar = "";
      if (this.listdh.length === 0) {
        navigationBar = [];
      }
      // 编码
      navigationBar = encodeURI(JSON.stringify(this.listdh));
      // console.log("lifeIdJsonArray", navigationBar)
      // 解码
      // console.log("解码", JSON.parse(decodeURI(navigationBar)));

      //参数：checkedCities（选中的第三方列表）、navigationBar（底部导航栏配置）
      const updateThatsLifeVo = {
        tbThirdPartySmallRoutineType: this.checkedCities,
        navigationBar: navigationBar
      }
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/bottomNavigation",
          updateThatsLifeVo
      );
      if (200 === res.code) {
        this.$message.success("「底部导航栏」配置成功");
      } else {
        this.$message.error("「底部导航栏」配置失败:" + res.message);
      }
      this.loadingdh = false; //关闭加载中
    },
    //添加一行数据
    adddh(){
      if(this.listdh_max_length == this.listdh.length)	{	//如果长度超过了，就不要再添加了
        this.$message.error("超过长度了,无法再添加");
      }else{
        this.listdh.push(this.navigation_type_options[0]);	//默认添加第一个
      }
    },
    //清空所有的数据
    cleardh(){
      this.listdh =[];
    },
    //排序的名次上升，指排序的下标上升
    row_updh(index){
      let temp = this.listdh[index] ;
      this.$set(this.listdh,index,this.listdh[index-1]);
      this.$set(this.listdh,index-1,temp);
    },
    //排序的名次下降，指排序的下标下降
    row_downdh(index){
      let temp = this.listdh[index] ;
      this.$set(this.listdh,index,this.listdh[index+1]);
      this.$set(this.listdh,index+1,temp);
    },
    //删除一行的数据
    delete_rowdh(index){
      this.listdh.splice(index,1);
    },
    //底部导航栏类型改变处理函数
    navigationTypeChangeHandler(selected, scope) {
      //selected 级联选择器选中节点的 values 值
      //scope 表格当前单元格所在行列及数据项索引组成的对象
      // 从选项列表数据中选出当前选中的节点
      // 当前查找的层级
      let level = 0
      // 当前查询层级的列表
      let list = this.navigation_type_options
      const find = () => {
        // 查找符合当前层级 type 的项
        list = list.find(item => item.value === selected[level])
        // 查到最后一个层级
        // 因为在此层级的列表就是页面中当前选中的节点所在的列表
        if (level >= selected.length - 1) {
          // this.list 为表格中渲染的数据
          // 更新当前在页面中修改的表格项(行)
          this.listdh.splice(scope.$index, 1, list)
          // 推出递归
          return
        }
        // 递归
        level++
        list = list.children
        find()
      }
      // 开始查询
      find()
    },
  },
  computed:{

  },
  mounted() {
    this.getBottomNavigation();
  }
}
</script>


<style scoped lang="less">
.buttonUseDeploy{
  margin-top: 20px;
}
.xcxkf{
  margin-top: 20px;
}
</style>