<template>
<el-dialog title="修改配置" :visible="true"		class="dialog"	width="1800px"	@close="close">
	<div	class="config_container">
		<el-form	:model="updateForm"	label-width="auto" 	class="form_class">
			<el-divider content-position="left">首页自动跳转</el-divider>
				<!-- 跳转h5 -->
				<el-form-item label="最终首页跳转h5" prop="jumpToH5">
					<el-input  v-model="updateForm.jumpToH5"  placeholder="请输入跳转h5"  clearable></el-input>
				</el-form-item>
				<!-- 是否开启 -->
				<el-form-item label="最终跳转h5" prop="jumpToH5Status">
					<el-switch v-model="updateForm.jumpToH5Status"> </el-switch>
				</el-form-item>
				<!-- 是否开启 -->
				<el-form-item label="第一次跳转h5" prop="isPreJumpToH5">
					<el-switch v-model="updateForm.isPreJumpToH5"> </el-switch>
				</el-form-item>
				<!-- 跳转h5 -->
				<el-form-item label="第一次跳转h5" prop="preJumpToH5">
					<pre_jump_h5_list_editor		:input_list.sync="updateForm.preJumpToH5"></pre_jump_h5_list_editor>
				</el-form-item>
			<el-divider content-position="left">生活号</el-divider>
				<!-- 是否开启生活号 -->
				<el-form-item label="生活号" prop="isShowLifeId">
					<el-switch v-model="updateForm.isShowLifeId"> </el-switch>
				</el-form-item>
				<el-form-item label="生活号列表" prop="lifeIdJsonArray">
					<life_id_list_editor		:input_list.sync="updateForm.lifeIdJsonArray"></life_id_list_editor>
				</el-form-item>
			<el-divider content-position="left">其他功能</el-divider>
				<!-- 灯火广告 -->
				<el-form-item label="灯火广告" prop="lightAdStatus">
					<el-switch v-model="updateForm.lightAdStatus"> </el-switch>
				</el-form-item>
				<!-- 轮播图 -->
				<el-form-item label="轮播图" prop="rotationMap">
					<template>
						<el-switch v-model="updateForm.rotationMap"	> </el-switch>
					</template>
				</el-form-item>
				<el-form-item label="弹窗小广告" prop="coupon">
					<template>
						<el-switch v-model="updateForm.coupon"> </el-switch>
					</template>
				</el-form-item>
				<el-form-item label="菜单按钮" prop="smallMallMenuTemplateId">
					<small_mall_menu_picker	v-if="updateForm.smallMallMenuTemplateId"
						:smallMallMenuTemplateId.sync="updateForm.smallMallMenuTemplateId"></small_mall_menu_picker>
				</el-form-item>
				
			<el-divider content-position="left">广告图片</el-divider>
				<el-form-item label="是否展示" 		prop="isShowAdvertiseImage"	>
					<el-switch v-model="updateForm.isShowAdvertiseImage"> </el-switch>
				</el-form-item>
				<el-form-item label="跳转的类型" prop="advertiseImageJumpType"	>
					<el-select v-model="updateForm.advertiseImageJumpType" placeholder="请选择">
						<el-option		label="h5"	value="h5"></el-option>
						<el-option		label="app"	value="app"></el-option>
					</el-select>
				</el-form-item> 	
				<el-form-item label="跳转的h5路径" prop="advertiseImageH5">
					<el-input  v-model="updateForm.advertiseImageH5"	placeholder="请输入跳转h5"  clearable></el-input>
				</el-form-item>
				<el-form-item label="跳转的appId" prop="advertiseImageAppId">
					<el-input  v-model="updateForm.advertiseImageAppId"  placeholder="请输入跳转的appId"  clearable></el-input>
				</el-form-item>
				<el-form-item label="图片设置">
                    <image_choose
                        :image_url.sync="updateForm.advertiseImageUrl"
                        prefix_path="redpacket/advertise"
                        is_show_cdn
                    >
					</image_choose>
				</el-form-item>
				
						
			<!-- 新人红包 -->
			<el-divider content-position="left">首页展示红包</el-divider>
				<el-form-item label="口令红包" prop="isShowPasswordsRedEnvelope">
					<el-switch v-model="updateForm.isShowPasswordsRedEnvelope"></el-switch>
				</el-form-item>
				<el-form-item label="新人红包(表单)" prop="showRedEnvelopeForNewcomers">
					<el-switch v-model="updateForm.showRedEnvelopeForNewcomers"></el-switch>
				</el-form-item>
				<el-form-item label="注册领红包" prop="isShowRegisterForRedpacket">
					<el-switch v-model="updateForm.isShowRegisterForRedpacket"></el-switch>
				</el-form-item>
				<el-form-item label="注册领红包h5" prop="registerForRedpacketUrl">
					<el-input  v-model="updateForm.registerForRedpacketUrl"  placeholder="请输入跳转h5"  clearable></el-input>
				</el-form-item>
					
			<el-divider content-position="left">云码</el-divider>
				<el-form-item label="是否展示云码内容"	 prop="isShowYunma"	>
					<el-switch v-model="updateForm.isShowYunma"></el-switch>
				</el-form-item>
				<el-form-item label="是否展示入会"	 prop="isShowYunmaMembership"	>
					<el-switch v-model="updateForm.isShowYunmaMembership" ></el-switch>
				</el-form-item>W
				<el-form-item label="是否展示聚合拉新"	 prop="isShowYunmaAcquisition"	>
					<el-switch v-model="updateForm.isShowYunmaAcquisition" ></el-switch>
				</el-form-item>
			<el-divider content-position="left"	>淘宝客</el-divider>
				<el-form-item label="是否展示淘宝客内容"	 prop="isShowYunma"	>
					<el-switch v-model="updateForm.isShowTaobao" inactive-value="false"	></el-switch>
				</el-form-item>
			<el-divider content-position="left">是否跳转卡</el-divider>
				<el-form-item label="是否跳转卡" prop="isShowTemplateContent">
					<el-switch v-model="updateForm.isShowTemplateContent"></el-switch>
				</el-form-item>
					
			<el-divider content-position="left">陆卡汇</el-divider>
				<el-form-item 	label="弹窗" prop="lucardPopCardId"	v-if="lucard_pop.is_show">
					<el-select v-model="updateForm.lucardPopCardId" placeholder="请选择" @change="(e)=>{updateForm.lucardPopCardId = e;$forceUpdate();}">
						<el-option		v-for="(item,index) in lucard_pop.template_list"	:key="index"
							:label="item.title+'-'+item.jumpType"	:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="信用卡" prop="lucardCardId"	v-if="lucard_pop.is_show">
						<el-select v-model="updateForm.lucardCardId" placeholder="请选择" @change="(e)=>{updateForm.lucardCardId = e;$forceUpdate();}">
							<el-option		v-for="(item,index) in lucard_pop.card_template_list"	:key="index"
								:label="item.title+'-'+item.jumpType"	:value="item.id"></el-option>
						</el-select>
				</el-form-item>
			<el-divider content-position="left">标题的修改</el-divider>
				<el-form-item label="修改小程序的标题" prop="indexTitle">
					<el-input  v-model="updateForm.indexTitle"  placeholder="请输入小程序的标题"  clearable></el-input>
				</el-form-item>
			<el-divider content-position="left">小程序的客服</el-divider>
				<el-form-item label="小程序的客服" prop="customerServiceFaq">
					<customer_service_faq	:input_list.sync="updateForm.customerServiceFaq"></customer_service_faq>
				</el-form-item>
			<el-divider content-position="left">底部导航栏</el-divider>
				<el-form-item label="底部导航栏" prop="customerServiceFaq">
					<navigation_bar	:input_list.sync="updateForm.navigationBar"></navigation_bar>
				</el-form-item>
			<el-divider content-position="left">小商城</el-divider>
				<jump_object_parse
                    :jump_object_json.sync="updateForm.smallMallSwiperJson"
                    prefix_path="redpacket/small_mall_1/swiper"
                    content_title="顶部轮播图">
        </jump_object_parse>
			<el-divider content-position="left">首页</el-divider>
				<jump_object_parse
            :jump_object_json.sync="updateForm.homeSwiperJson"
            prefix_path="redpacket/small_mall_1/swiper"
            content_title="顶部轮播图">
        </jump_object_parse>
			<el-divider content-position="left">记账本</el-divider>
				<jump_object_parse	
					:jump_object_json.sync="updateForm.accountBookSwiperJson"	
					prefix_path="redpacket/accountBook/swiper"		
					content_title="底部轮播图" 
				></jump_object_parse>
			<el-divider content-position="left">只有轮播图的首页</el-divider>
				<jump_object_parse	
					:jump_object_json.sync="updateForm.indexSwiperJson"	
					prefix_path="redpacket/swiper/swiper"
					content_title="整个轮播图"
				></jump_object_parse>
      <!-- ======================================================================================== 商城配置项 ======= -->
			<el-divider content-position="left">商城</el-divider>
				<el-form-item label="商城顶部轮播图" prop="mallIndexSwiperUrl">
					<image_choose
                        :image_url.sync="updateForm.mallIndexSwiperUrl"
                        prefix_path="redpacket/mall/indexSwiper"
                        is_show_cdn>
                    </image_choose>
				</el-form-item>
      <el-form-item label="是否展示菜单按钮列表" prop="isShowMallMenuList">
        <el-switch v-model="updateForm.isShowMallMenuList"></el-switch>
      </el-form-item>
      <el-form-item label="是否展示抵用券领取按钮" prop="isShowMallCoupon">
        <el-switch v-model="updateForm.isShowMallCoupon"></el-switch>
      </el-form-item>
      <el-form-item label="抵用券抵扣金额" prop="couponAmount">
        <el-input-number v-model="updateForm.couponAmount" :step="1" size="medium"></el-input-number>
      </el-form-item>
      <el-form-item label="抵用券领取按钮 logo" prop="couponBtnLogo">
        <image_choose :image_url.sync="updateForm.couponBtnLogo"
                      prefix_path="mall/coupon/btn/logo"></image_choose>
      </el-form-item>
      <el-form-item label="抵用券领取按钮标题" prop="couponBtnTitle">
        <el-input v-model="updateForm.couponBtnTitle" :maxlength="50" show-word-limit placeholder="请输入抵用券领取按钮标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="抵用券弹窗标题" prop="couponPopTitle">
        <el-input v-model="updateForm.couponPopTitle" :maxlength="50" show-word-limit placeholder="请输入抵用券弹窗标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示领取表单按钮" prop="isShowMallForm">
        <el-switch v-model="updateForm.isShowMallForm"></el-switch>
      </el-form-item>
      <el-form-item label="领取表单的标题" prop="mallFormTitle">
        <el-input v-model="updateForm.mallFormTitle" placeholder="请输入领取表单的标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示口令红包" prop="isShowMallPwdRedpacket">
        <el-switch v-model="updateForm.isShowMallPwdRedpacket"></el-switch>
      </el-form-item>
      <el-form-item label="是否展示加入粉丝群" prop="isShowMallFanGroup">
        <el-switch v-model="updateForm.isShowMallFanGroup"></el-switch>
      </el-form-item>
      <el-form-item label="商品当前价格说明" prop="mallGoodsPriceDescCurrent">
        <el-input v-model="updateForm.mallGoodsPriceDescCurrent" placeholder="请输入商品当前价格说明(未被划线的商品价格前的文字)" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示商品的原价" prop="isShowMallGoodsPriceOrigin">
        <el-switch v-model="updateForm.isShowMallGoodsPriceOrigin"></el-switch>
      </el-form-item>
      <el-form-item label="商品原始价格说明" prop="mallGoodsPriceDescOrigin">
        <el-input v-model="updateForm.mallGoodsPriceDescOrigin" placeholder="请输入商品原始价格说明(划线的商品价格前的文字)" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示商品的购买量" prop="isShowMallGoodsSaleCnt">
        <el-switch v-model="updateForm.isShowMallGoodsSaleCnt"></el-switch>
      </el-form-item>
				<el-form-item label="商城首页要展示的商品列表" prop="mallIndexGoodsList">
					<mall_goods_list	:input_list.sync="updateForm.mallIndexGoodsList"></mall_goods_list>	
				</el-form-item>
				<el-form-item label="商城首页轮播图条轮的h5路径">
					<el-input v-model="updateForm.mallIndexSwiperH5Url" placeholder="请输入跳转h5链接"></el-input>
				</el-form-item>
				<el-form-item label="商城首页轮播图条轮的h5路径开关">
					<el-switch v-model="updateForm.mallIsSwiperH5Url" active-color="#13ce66" style="display: flex; margin-top: 9px;"></el-switch>
				</el-form-item>
      <!-- ======================================================================================== 商城配置项 ======= -->
			<el-divider content-position="left">自定义广告第一条</el-divider>
				<el-form-item label="是否开启" prop="isCustomAdvertise">
					<el-switch v-model="updateForm.isCustomAdvertise"> </el-switch>
				</el-form-item>
				<el-form-item label="类别" prop="customAdvertiseType">
					<customerAdvertiseType  :type.sync="updateForm.customAdvertiseType"></customerAdvertiseType>
				</el-form-item>
			<el-divider content-position="left">粉丝群</el-divider>	
				<fan_group_editor	
					:isShowFanGroup.sync = "updateForm.isShowFanGroup"
					:fanGroupTemplateId.sync = "updateForm.fanGroupTemplateId"
					:fanGroupTopLeftSlogan.sync = "updateForm.fanGroupTopLeftSlogan"
					v-if="is_load_success">
				</fan_group_editor>
			<el-divider content-position="left">翻一翻</el-divider>	
				<overturn	
					:overturnJson.sync="updateForm.overturnJson"
					v-if="is_load_success">
				</overturn>
            <!-- 灯火广告控制按钮 -->
            <el-divider content-position="left">灯火</el-divider>
                <el-form-item label="任务（类似云码）" prop="jumpToH5">
                    <el-switch v-model="updateForm.isShowXLightTasks"></el-switch>
                </el-form-item>
                <el-form-item label="浏览15秒给奖励的" prop="jumpToH5">
                    <el-switch v-model="updateForm.isShowXLightRewardedAd"></el-switch>
                </el-form-item>
                <el-form-item label="全屏广告" prop="jumpToH5">
                    <el-switch v-model="updateForm.isShowXLightInterstitialAd"></el-switch>
                </el-form-item>
            <el-form-item label="展示灯火任务中的浏览任务" prop="jumpToH5">
                <el-switch v-model="updateForm.isShowXLightTasksPageView"></el-switch>
            </el-form-item>
            <el-form-item label="展示灯火任务中的资产拉新任务" prop="jumpToH5">
                <el-switch v-model="updateForm.isShowXLightTasksAcquisition"></el-switch>
            </el-form-item>
            <el-form-item label="展示灯火任务中的转化营销任务" prop="jumpToH5">
                <el-switch v-model="updateForm.isShowXLightTasksRevenue"></el-switch>
            </el-form-item>
            <el-form-item label="展示灯火任务中的其他任务" prop="jumpToH5">
                <el-switch v-model="updateForm.isShowXLightTasksOther"></el-switch>
            </el-form-item>
            <el-form-item label="灯火广告的排序" prop="jumpToH5">
                <el-select v-model="updateForm.xlightTasksOrder" placeholder="请选择">
                    <el-option
                        v-for="item in xLightTasks0rdeOptionList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- 信用卡配置项 -->
            <el-divider content-position="left">信用卡</el-divider>
            <el-form-item label="是否展示顶部图片" prop="isShowBackgroundImage">
              <el-switch v-model="updateForm.isShowBackgroundImage"></el-switch>
            </el-form-item>
            <el-form-item label="顶部图片跳转的类型" prop="backgroundImageJumpType">
              <el-select v-model="updateForm.backgroundImageJumpType" placeholder="请选择">
                <el-option label="h5" value="h5"></el-option>
                <el-option label="app" value="app"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="图片跳转的h5路径" prop="backgroundImageH5">
              <el-input v-model="updateForm.backgroundImageH5" placeholder="请输入跳转h5" clearable></el-input>
            </el-form-item>
            <el-form-item label="图片跳转的appId" prop="backgroundImageAppId">
              <el-input v-model="updateForm.backgroundImageAppId" placeholder="请输入跳转的appId" clearable></el-input>
            </el-form-item>
            <el-form-item label="顶部图片设置">
              <image_choose :image_url.sync="updateForm.backgroundImageUrl" prefix_path="redpacket/lucard/topSwiper">
              </image_choose>
            </el-form-item>
            <el-form-item label="是否展示卡片列表上方的标题" prop="isShowCardTitle">
              <el-switch v-model="updateForm.isShowCardTitle"></el-switch>
            </el-form-item>
            <el-form-item label="卡片列表上方的标题的内容" prop="cardTitle">
              <el-input v-model="updateForm.cardTitle" placeholder="请输入标题的内容" clearable></el-input>
            </el-form-item>
            <el-form-item label="是否展示卡片列表" prop="isShowCardList">
              <el-switch v-model="updateForm.isShowCardList"></el-switch>
            </el-form-item>
            <el-form-item label="信用卡配置" prop="lucardListJson">
              <card_table v-model="updateForm.lucardListJson"></card_table>
            </el-form-item>
            <el-form-item label="卡片列表中按钮的名称" prop="buttonName">
              <el-input v-model="updateForm.buttonName" placeholder="请输入按钮的名称" clearable></el-input>
            </el-form-item>
            <!-- <el-divider content-position="left">消息轮播</el-divider>
                <el-form-item label="注册领红包h5" prop="messageCarousel">
                    <message_carousel
                        :input_list.sync="updateForm.messageCarousel">
                    </message_carousel>
                </el-form-item> -->
		</el-form>
		<div	class="right_content">
			<el-button	class="button"	@click="submit_update"	type="primary">对所有该类小程序应用该配置</el-button>
			<el-button	class="button"	@click="save_config"	type="primary">保存当前的配置</el-button>
			<el-button	class="button"	@click="submit_update_checked"	type="primary"
						:disabled="class_apps.select_list.length == 0||right_content.current_show == 'result'">
				只对勾选的该类小程序应用该配置
			</el-button>

			<el-button	class="button"	@click="operate('BUILD')"	type="primary">对所有该类小程序构建版本</el-button>
			<el-button	class="button"	@click="operate_checked('BUILD')"	type="primary" style="margin-right: 100px;"
						:disabled="class_apps.select_list.length == 0||right_content.current_show == 'result'">
				只对勾选的小程序构建版本
			</el-button>
<!--			<el-button	class="button"	@click="operate('ONLINE')"	type="primary">对所有该类小程序上架</el-button>-->
<!--			<el-button	class="button"	@click="operate_checked('ONLINE')"	type="primary"  style="margin-right: 180px;"-->
<!--						:disabled="class_apps.select_list.length == 0||right_content.current_show == 'result'">-->
<!--				只对勾选的小程序上架-->
<!--			</el-button>-->
<!--			<el-button	class="button"	@click="operate_checked('OFFLINE')"	type="primary" style="margin-right: 480px;"-->
<!--					:disabled="class_apps.select_list.length == 0||right_content.current_show == 'result'">-->
<!--				只对勾选的小程序下架-->
<!--			</el-button>-->
<!--			<el-button	class="button"	@click="operate('STATUS_UPDATE')"	type="primary">对所有该类小程序更新状态</el-button>-->
<!--			<el-button	class="button"	@click="operate_checked('STATUS_UPDATE')"	type="primary"  style="margin-right: 180px;"-->
<!--						:disabled="class_apps.select_list.length == 0||right_content.current_show == 'result'">-->
<!--				只对勾选的小程序更新状态-->
<!--			</el-button>-->
			<div	v-if="right_content.current_show == 'result'">
				<div	class="result_summary"	v-if="result.list.length!=0">
					<span>通过了：{{result.list.filter((item)=>{return	item.isCompletedInstructions}).length  }}</span>
					<span>总数：{{result.list.length}}</span>
					<el-button	class="button"	@click="()=>{result.is_filter = !result.is_filter }"	type="primary">是否过滤成功</el-button>
					<el-button	class="button"	@click="()=>{right_content.current_show = 'apps';class_apps.select_list=[];}"	type="primary">返回挑选小程序</el-button>
				</div>
				<el-table	class="result_table"	
						:data="result.list.filter(item=>{return  result.is_filter?!item.isCompletedInstructions:true})"	
						v-if="result.list.length!=0">
					<el-table-column v-for="(item,index) in result.columnName" :width="item.width" 
							:label="item.label" :prop="item.prop"  :key="index"></el-table-column>
					<el-table-column	><template slot-scope="scope">
						<el-tag	:type="scope.row.isCompletedInstructions ? 'success' : 'danger'"	disable-transitions>
							{{scope.row.isCompletedInstructions ? '完成' : '失败'}}
						</el-tag>
					</template></el-table-column>
				</el-table>
			</div>
			<div	v-if="right_content.current_show == 'apps'">
				<el-table	ref="table"	  :data="class_apps.list"	@selection-change="(e)=>{class_apps.select_list = e}">
					<el-table-column	type="selection"	width="50"></el-table-column>
					<el-table-column	v-for="(item,index) in class_apps.columnName"	:label="item.label"	
							:prop="item.prop"	:width="item.width"		:key="index"></el-table-column>
				</el-table>
			</div>
		</div>
	
	</div>
	<div slot="footer" class="dialog-footer">
		<el-button @click="close">关闭页面</el-button>
	</div>
</el-dialog>
</template>

<script>
	import axios from 'axios';
	// import cdn_image_choose from '../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/cdn_image_choose.vue';
	import life_id_list_editor from '../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/life_id_list_editor.vue';
	import pre_jump_h5_list_editor from '../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/pre_jump_h5_list_editor.vue';
	import customer_service_faq from '../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/customer_service_faq.vue';
	import navigation_bar from '../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/navigation_bar.vue';
	import small_mall_menu_picker from "../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/small_mall_menu_picker.vue";
	import jump_object_parse from '../../applet1/childrenPage/smallroutineConfiguration/jump_object_parse.vue';
	import mall_goods_list from "../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/mall_goods_list.vue";
	import image_choose from '../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/image_choose.vue';
	import customerAdvertiseType from '../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/redpacket/customAdvertiseType_choose.vue';
	import fan_group_editor from '../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/fan_group_editor.vue';
	import message_carousel from "../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/message_carousel.vue";
  import el from "element-ui/src/locale/lang/el";
  import card_table from "@/components/thirdParty/other/lucard/component/card_table.vue";
  export default{
    components:{
   		// cdn_image_choose, 
		life_id_list_editor, pre_jump_h5_list_editor, customer_service_faq, navigation_bar,
		small_mall_menu_picker,jump_object_parse,mall_goods_list,image_choose,customerAdvertiseType,fan_group_editor,card_table,
	},
    props: {
        sort_data_list:{},//所有的类别
        current_index:{},//当前左边导航栏选中的类别的下标 index
		appletType:{type:String},	//模板类别
      appletTypeList:{type:Array}, //选中的第三方（list.vue页面中选择第三方多选项，这里通过batch_navigator.vue页面传递过来的）
    },
    data() {
        return {
            updateForm_origin: {
            	jumpToH5:"",
            	jumpToH5Status:false,
            	isPreJumpToH5:false,
            	preJumpToH5:"[]",
            	isShowLifeId:false,
            	lifeIdJsonArray:"[]",
            	lightAdStatus:false,
            	rotationMap:false,
            	coupon:false,
            	isShowAdvertiseImage:false,
            	advertiseImageJumpType:"h5",
            	advertiseImageH5:"",
            	advertiseImageAppId:"",
            	advertiseImageUrl:"",
            	isShowPasswordsRedEnvelope:false,
            	showRedEnvelopeForNewcomers:false,
            	isShowYunma:false,
            	isShowYunmaMembership:false,
            	isShowYunmaAcquisition:false,
            	isShowTaobao:false,
            	isShowTemplateContent:false,
				isShowRegisterForRedpacket:false,
				registerForRedpacketUrl:"",
				mallIndexSwiperUrl:"",
				indexTitle:"首页",
				customerServiceFaq:'[]',
				navigationBar:'[]',
				smallMallMenuTemplateId:"1",
				smallMallSwiperImageUrl:"",
				smallMallSwiperJumpH5:"",
              // --------------------------------------------- 商城配置项 ------
              isShowMallMenuList: false,
              isShowMallCoupon: false,
              couponAmount: 0,
              couponBtnLogo: '',
              couponBtnTitle: '',
              couponPopTitle: '',
              isShowMallForm: false,
              mallFormTitle: '',
              isShowMallPwdRedpacket: false,
              isShowMallFanGroup: false,
              mallGoodsPriceDescCurrent: '',
              isShowMallGoodsPriceOrigin: false,
              mallGoodsPriceDescOrigin: '',
              isShowMallGoodsSaleCnt: false,
				mallIndexGoodsList:'[]',
				mallIndexSwiperH5Url:'',
				mallIsSwiperH5Url:false,
              // --------------------------------------------- 商城配置项 ------
				customAdvertiseType:null,
				isCustomAdvertise:true,
				messageCarousel:'[]',
                // 灯火广告控制按钮初始状态
                isShowXLightInterstitialAd: false,
                isShowXLightRewardedAd: false,
                isShowXLightTasks: false,
                isShowXLightTasksPageView: false,
                isShowXLightTasksAcquisition: false,
                isShowXLightTasksRevenue: false,
                isShowXLightTasksOther: false,
                xlightTasksOrder: 'BY_NONE',
            //   信用卡配置项
              isShowBackgroundImage: '',
              backgroundImageJumpType: 'h5',
              backgroundImageH5: '',
              backgroundImageAppId: '',
              backgroundImageUrl: '',
              isShowCardTitle: false,
              cardTitle: '',
              isShowCardList: false,
              lucardListJson: '[]',
              buttonName: '',
            },
            updateForm:{
            	
            },
			is_load_success:false,	//数据是否加载完成
			goodsTableData:[],
            lucard_pop:{
            	template_list:[],
				is_show:false,
				card_template_list:[],
            	app_current_pop:[],
            	temp_data_id:null,
            	columnName:[	//每一列的数据
            		{label:"标题",prop:"title",map_type:'none',width:120},
            		{label:"跳转类型",prop:"jumpType",map_type:'base',map:{'h5':'h5','api':'api'},width:100},
            		{label:"是否有提示",prop:"isShowNotification",map_type:'boolean',map:{'false':'无','true':'有'} ,width:100},
            		{label:"h5路径",prop:"h5Path",map_type:'none',width:250},
            		{label:"等待时间",prop:"waitingTime",map_type:'none' ,width:100},
            		{label:"产品码",prop:"posterCode",map_type:'none' ,width:280},
            		// {label:"图片路径",prop:"imagePath",map_type:'image' ,width:0},
            	],
            },
            result:{	//提交更新后的结果
            	list: [],	//每个小程序更新是否更新的结果
            	columnName:[
            		{label:"名字",prop:"name",width:200},
					{label:"描述",prop:"operateMessage",width:400},
            	],
            	is_filter:false,
            },
			right_content:{
				current_show:"apps"
			},
			class_apps:{	//类中的所有小程序
				list:[],
				columnName:[
					{label:"名字",width:220,prop:"name"},
					{label:"版本号",width:70,prop:"smallRoutineVersion"},
					{label:"审核状态",width:80,prop:"submissionStatus"},
					{label:"备注",width:160,prop:"remark"},
				],
				select_list:[]
			},
			
			submissionStatus_map:{
				'INIT':'开发中',
				'AUDITING':'审核中',
				'AUDIT_REJECT':'审核驳回',
				'WAIT_RELEASE':'待上架',
				'BASE_AUDIT_PASS':'准入不可营销',
				'GRAY':'灰度中',
				'RELEASE':'已上架',
				'OFFLINE':'已下架',
				'AUDIT_OFFLINE':'被强制下架',
			},
			select_apps_class:{
				current:null,
				options:[
					{label:"全部",value:"ALL"},
					{label:"审核驳回",value:"AUDIT_REJECT"},
					{label:"审核中",value:"AUDITING"},
					{label:"待上架",value:"WAIT_RELEASE"},
					{label:"已上架",value:"RELEASE"},
				]
			},
			test:null,
            // 灯火广告排序的选项列表
            xLightTasks0rdeOptionList: [
                {value: 'BY_REWARD_DESC', label: '按照任务奖励降序'},
                {value: 'BY_REWARD_ASC', label: '按照任务奖励升序'},
                {value: 'BY_NONE', label: '不按顺序，接口怎么给就什么顺序'},
            ],

        }
    },
    methods:{
		async	initialize_data(){
			// 获取lucard的弹窗内容-----获取所有模板弹窗
			let res_lucard_pop	= await axios.get(this.$httpUrl+"tb-third-party-wang-lucard-card/listAllPop");
			this.lucard_pop.template_list = res_lucard_pop.data ;
			let res_lucard	= await axios.get(this.$httpUrl+"tb-third-party-wang-lucard-card/listAll");
			this.lucard_pop.card_template_list = res_lucard.data ;
			this.lucard_pop.is_show = true;
			// 获取所有的配置
			this.updateForm = JSON.parse(JSON.stringify(this.updateForm_origin));
			this.goodsTableData= JSON.parse(this.updateForm.mallIndexGoodsList);
			// console.log(this.updateForm)
			let configuration = JSON.parse(this.sort_data_list[this.current_index].configurationJson);
			for(let key in configuration){
				this.updateForm[key] = configuration[key];
			}
			this.is_load_success = true ;	//数据加载完成
			//获取这个类的所有app
			const {data:{message:result}}= await axios.get(this.$httpUrl+
					"tb-third-party-wang-batch-class/listAppsByClass?batchClassId="+
					this.sort_data_list[this.current_index].id+"&appletType="+this.appletType); 
			this.class_apps.list = result.map(item=>{
				item.submissionStatus = this.submissionStatus_map[item.submissionStatus];
				return item;
			});
		},
		async	submit_update(){		//提交更新的操作
      console.log(this.sort_data_list[this.current_index].id)
			await this.save_config();
			const {data:{message:web_result}} = await axios ({
				url:this.$httpUrl+"tb-third-party-wang-batch-class/updateBatchClassAppsConfig?batchClassId="
						+this.sort_data_list[this.current_index].id	,	
				method:"put",
			})
			this.right_content.current_show = "result";
			this.result.list = web_result;	
		},
		async	save_config(){	//保存配置项
      console.log("this.updateForm看看", this.updateForm)
			const {data:{message:web_result}}= await axios({
				url:this.$httpUrl+"tb-third-party-wang-batch-class/update",	
				method:"put",
				data:{
					id:this.sort_data_list[this.current_index].id,
					configurationJson:JSON.stringify(this.updateForm),
				}
			});
			if(web_result == true ){
				this.$message.success("成功更改");
				this.$emit("fresh_batch_class");
			}else{
				this.$message.error("更改失败");
			}
		},
		
		handleDelete(index, row) {
			this.goodsTableData.splice(index,1);
		},
        close(){	//关闭本弹窗
			this.$emit("close");
		},
		//获取所有商品
		async getAll(){
			let res	= await axios.get(this.httpUrl+"tb-third-party-wang-mall-goods/page");
			this.goodsTableData = res.data.message.records;
		},
		async submit_update_checked(){	//勾选小程序进行更新
			await this.save_config();
			const {data:{message:web_result}} = await axios ({
				url:this.$httpUrl+"tb-third-party-wang-batch-class/updateCheckedBatchClassAppsConfig",
				data:{
					batchClassId:this.sort_data_list[this.current_index].id	,
					smallRoutines:this.class_apps.select_list,
				},
				method:"put",
			})
			this.right_content.current_show = "result";
			this.result.list = web_result;
		},
		async operate_checked(operation = "BUILD"){
			const {data:{message:web_result}} =  await axios ({
				url:this.$httpUrl+"tb-third-party-wang-batch-class/updateAppsByBatchIdAndApps",
				data:{
					batchClassId:this.sort_data_list[this.current_index].id	,
					smallRoutines:this.class_apps.select_list,
					operation:operation
				},
				method:"put",
			})
			this.right_content.current_show = "result";
			this.result.list = web_result;
			this.initialize_data();
		},
		async operate(operation="BUILD"){
			const {data:{message:web_result}} =  await axios ({
				url:this.$httpUrl+"tb-third-party-wang-batch-class/updateAppsByBatchId",
				data:{
					batchClassId:this.sort_data_list[this.current_index].id	,
					operation:operation
				},
				method:"put",
			})
			this.right_content.current_show = "result";
			this.result.list = web_result;
			this.initialize_data();
		},


    },
    computed:{
		
	},
    created(){
        this.initialize_data();
    },

}
</script>

<style  lang="less" scoped>
	.dialog{
		display:flex;		flex-direction: column;
		.config_container{
			display: flex;
      justify-content: space-evenly;
			width: 100%;	min-width: 70%;
			
			.form_class{
        width: 1100px;
				.div_main_img{
					width: 100px;	height: 100px;	
				}
			}
			.right_content{
				width: 700px;
			}
			.button{
				margin-bottom: 20px;
        margin-left: 20px;
			}
			.result_summary{
				width: 100%;
				margin-bottom: 20px;
				display: flex; 	justify-content: space-between;	align-items: baseline;
			}
			.result_table{
				overflow-y: scroll;
				height: 85%;
			}
			
			/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
			::-webkit-scrollbar {
				width: 0.75rem;
				height: 0.75rem;
				background-color: #F5F5F5;
			}
			
			/*定义滚动条轨道 内阴影+圆角*/
			::-webkit-scrollbar-track {
				// box-shadow: inset 0 0 0.375rem #999;
				border-radius: 0.625rem;
				background-color: #F5F5F5;
			}
			
			/*定义滑块 内阴影+圆角*/
			::-webkit-scrollbar-thumb {
				border-radius: 0.625rem;
				box-shadow: inset 0 0 0.375rem #999;
				background-color: #777;
			}
		}
	}

    // 样式穿透，修改 element ui 选择框的样式
    ::v-deep .el-select {
        width: 300px;
    }


</style>