
<template>
  <div>
    <el-form :model="updateForm" label-width="auto">
      <el-form-item label="是否开启" class="text">
        <el-switch v-model="updateForm.isCustomAdvertise"></el-switch>
      </el-form-item>
      <el-form-item label="类别" prop="customAdvertiseType">
        <el-select :value="updateForm.customAdvertiseType" placeholder="请选择"  @change="chooseType" >
          <el-option  v-for="item in advertiseTypeOptions"  :key="item.key"  :label="item.name"  :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-button :type="loading? 'info': 'primary'" icon="el-icon-plus" plain round
               @click="updateIsCustomAdvertise()" class="buttonUseDeploy" :loading="loading">
      {{ loading? '加载中' : '执 行 自 定 义 广 告 第 一 条 配 置' }}
    </el-button>
  </div>
</template>

<script>
  import axios from "axios";
  import {mapState} from "vuex";

  export default {
    components: {

    },
    props: [
      'checkedCities'
    ],
    data() {
      return {
        //需要的数据字段
        updateForm: {
          tbThirdPartySmallRoutineType: [],
          isCustomAdvertise: false,
          customAdvertiseType: "",
        },
        advertiseTypeOptions:null,
        loading: false,
      }
    },
    methods: {
      // 查询上一次数据接口
      async getIsCustomAdvertise() {
        const { data: res } = await this.$http(
            "/thirdPartySmallRoutineConfiguration/getIsCustomAdvertise",
        );
        // console.log("查询上一次数据接口：", res.message)
        if (200 === res.code && res.message != null) {
          this.updateForm.isCustomAdvertise = res.message.isCustomAdvertise;
          this.updateForm.customAdvertiseType = res.message.customAdvertiseType;
        }
      },
      // 更新数据接口
      async updateIsCustomAdvertise() {
        this.loading = true; //开启加载中
        if (this.checkedCities.length === 0) {
          this.$message.error("请先选择第三方");
          this.loading = false; //关闭加载中
          return;
        }
        this.updateForm.tbThirdPartySmallRoutineType = this.checkedCities;
        // console.log("传递的数据：", this.updateForm)
        const { data: res } = await this.$http.post(
            "/thirdPartySmallRoutineConfiguration/updateIsCustomAdvertise",
            this.updateForm
        );
        if (200 === res.code) {
          this.$message.success("「自定义广告第一条」配置成功");
        } else {
          this.$message.error("「自定义广告第一条」配置失败:" + res.message);
        }
        this.loading = false; //关闭加载中
      },
      // 选项功能
      async    initialize_data(){
        const {data:{message:res}} =    await axios({
          url:this.httpUrl + 'tb-third-party-wang-redpacket-custom-advertise/list'
        }) ;
        this.advertiseTypeOptions  = res;
      },
      chooseType(choose_key){
        this.$emit("update:type",choose_key);
      }
    },
    computed:{
      ...mapState({
        httpUrl:state=>state.global_base_url.main
      })
    },
    mounted() {
      this.getIsCustomAdvertise()
    },
    created() {
      setTimeout(
          this.initialize_data,
          0
      );
    }
  }
</script>


<style scoped lang="less">
.text{
  font-size: 30px;
}
.buttonUseDeploy{
  margin-top: 20px;
}
</style>