<!-- 
    这个是用来做 菜单栏的按钮选用page时，选择对应路径的配置 
-->
<template>
<div class="whole_container">
    <type_picker    @smallType="handler_new_small_type" @pagePath="e=>pagePath=e"   @bigType="handler_new_big_type"></type_picker>
    <data_table  :data_for_table="data_for_table"    :pageType="pageType"   :bigType="bigType"
       :column_name="column_name" :pagePath ="pagePath"
        @page="(page_index)=>{page(page_index,pageType);}"></data_table>
</div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios'
import type_picker from './defaultConfig/type_picker.vue'
import data_table from './defaultConfig/data_table.vue'
import * as utility from '@/services/wCommonFunction.js'

export default{
    components:{
        data_table,
        type_picker
    },
    props: {
        
    },
    data() {
        return {
            // 页面类别,决定了表的数据和表的额外列 
            pageType:null,   //当前类别
            bigType:null,
            pagePath:null,
            //表的列名
			column_name:[
                {label:"页面路径",prop:"pagePath",map_type:'textarea',width:350},
				{label:"标题",prop:"name",map_type:'none',width:200},
                // {label:"页面类型",prop:"pageType",map_type:'base',map:{},width:160},
                // {label:"排序(越大越靠前)",prop:"sortIndex",map_type:'none'},
				{label:"描述语句",prop:"describeText",map_type:'textarea'},
			],
            //对应表的列名，有一些表需要额外的列
            additional_column_name:{
                MALL:[{label:"商品id号",prop:"mallGoodId",map_type:"MALL",width:190}],
                MALL_GOODS_LIST:[{label:"商品列表",prop:"mallGoodsList",map_type:"MALL_GOODS_LIST",width:190}],
                MAP_OF_POI_SEARCH:[{label:"关键词",prop:"mapOfPoiSearchKeywords",map_type:"none",width:160}],
                CALCULATOR:[{label:"类别",prop:"calculatorCode",map_type:"none",width:160}],
                CUSTOMER_SERVICE:[{label: "客服词条配置",prop: "customerServiceFaq",map_type: 'customer'}]
            },
            //表的数据
            data_for_table:{
                records:[], //记录，即每一项
                current:1,  //当前页
                pages:null, //总的页数
            }
        }
    },
    methods:{
        //处理附加项的类别，也就是更改this.column_name，让表的列随类别的变动而变动
        //（例如：如果是商城，就会多一列来显示和填写商品的id号）
        handler_additional_column(type){
            //如果附加项需要条件的话,没有this.additional_column_name[type]返回undefined
            let additional_column_array = this.additional_column_name[type] ;
            if(additional_column_array){
                this.column_name.splice(3,100,...additional_column_array);
            }else{
                this.column_name.splice(3,100);
            }
            //用来table 做 v-if="pageType==null"，用来控制重新加载，否则表格会出错
            this.bigType = null;
            setTimeout(()=>{this.bigType = type},0);
        },
        //如果有更换新的类型 ，更新数据 和表格类型
        handler_new_small_type(smallType){
            this.pageType = smallType ;
            if(smallType == null){
                this.data_for_table.records = [];
            }else{
                this.page(1,smallType);
            }
        },
        handler_new_big_type(bigType){
            this.bigType = bigType ;
            this.handler_additional_column(bigType);
        },
        //获取表格中的数据
        async page(page_index,smallType){
            const res =  await axios({
                url:this.global_url+"tb-third-party-wang-redpacket-menu-page/pageByType?current="+
                        page_index+"&type="+smallType,
                method:"GET",
            });
            const data = utility.handleResponse(res);
            this.data_for_table = data ;
        },
    },
    computed:{
		...mapState({
            global_url:state=>state.global_base_url.main
        })
	},
    watch:{

    },
    created(){
        
    },
}
</script>

<style  lang="less" scoped>
.whole_container{
	background-color: #fafafa;
	border-radius: 10px;
	padding: 40px;
    flex: 1;
    display: flex;  flex-direction: column;
}

</style>