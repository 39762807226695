
<template>
  <div>
    <el-form :model="updateForm" label-width="auto">
      <el-form-item label="是否开启粉丝群" class="text">
        <el-switch v-model="updateForm.isShowFanGroup"></el-switch>
      </el-form-item>
    </el-form>

    <el-button :type="loading? 'info': 'primary'" icon="el-icon-chat-line-round" plain round
               @click="updateIsShowFanGroup()" class="buttonUseDeploy" :loading="loading">
      {{ loading? '加载中' : '执 行 云 码 配 置' }}
    </el-button>
  </div>
</template>

<script>
  export default {
    props: [
      'checkedCities'
    ],
    data() {
      return {
        //需要的数据字段
        updateForm: {
          tbThirdPartySmallRoutineType: [],
          isShowFanGroup: false,
        },
        loading: false,

      }
    },
    methods: {
      // 查询上一次数据接口
      async getIsShowFanGroup() {
        const { data: res } = await this.$http(
            "/thirdPartySmallRoutineConfiguration/getIsShowFanGroup",
        );
        // console.log("查询上一次数据接口：", res.message)
        if (200 === res.code && res.message != null) {
          this.updateForm.isShowFanGroup = res.message.isShowFanGroup;
        }
      },
      // 更新数据接口
      async updateIsShowFanGroup() {
        this.loading = true; //开启加载中
        if (this.checkedCities.length === 0) {
          this.$message.error("请先选择第三方");
          this.loading = false; //关闭加载中
          return;
        }
        this.updateForm.tbThirdPartySmallRoutineType = this.checkedCities;
        // console.log("传递的数据：", this.updateForm)
        const { data: res } = await this.$http.post(
            "/thirdPartySmallRoutineConfiguration/updateIsShowFanGroup",
            this.updateForm
        );
        if (200 === res.code) {
          this.$message.success("「粉丝群」配置成功");
        } else {
          this.$message.error("「粉丝群」配置失败:" + res.message);
        }
        this.loading = false; //关闭加载中
      },
    },
    computed:{

    },
    mounted() {
      this.getIsShowFanGroup()
    }
  }
</script>


<style scoped lang="less">
.text{
  font-size: 30px;
}
</style>