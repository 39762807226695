
<template>
  <div>
    <div class="xcxkf"></div>
    <el-button type="primary" @click="addkf">添加</el-button>
    <el-button type="primary" @click="clearkf">清空</el-button>
    <div class="xcxkf"></div>
    <el-table	:data="listkf">
      <el-table-column  label="词条" width="500">
        <template slot-scope="scope">
          <el-select v-model="scope.row.id" placeholder="请选择">
            <el-option	v-for="(item,index) in faq_picker.options"
                        :key="index"	:label="item.title"	:value="parseInt(item.id)"	></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column  label="排序"	width="150">
        <template slot-scope="scope"	>
          <el-button type="primary" @click="row_upkf(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>
          <el-button type="primary" @click="row_downkf(scope.$index)" :disabled="scope.$index==listkf.length-1"	size="mini">向下</el-button>
        </template>
      </el-table-column>
      <el-table-column  label="操作"  width="80">
        <template slot-scope="scope"	>
          <el-button type="primary" @click="delete_rowkf(scope.$index)" size="mini">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button :type="loadingkf? 'info': 'primary'" icon="el-icon-service" plain round
               @click="serveExecute()" class="buttonUseDeploy" :loading="loadingkf">
      {{ loadingkf? '加载中' : '执 行 小 程 序 客 服 配 置' }}
    </el-button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: [
    'checkedCities'
  ],
  data() {
    return {
      faq_picker:{
        options:[],
      },
      listkf:[{id: 3},{id: 4},{id: 13}],
      listkf_max_length: 5,
      loadingkf:false,
    }
  },
  methods: {
    // 查询上一次数据接口
    async getServeExecute() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getServeExecute",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        // 解码
        this.listkf = JSON.parse(decodeURI(res.message));
      }
    },
    // 修改数据接口
    async serveExecute() {
      // console.log("客服词条", this.listkf)
      // console.log("编译后的", encodeURI(JSON.stringify(this.listkf)))

      this.loadingkf = true; //开启加载中
      if (this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loadingkf = false; //关闭加载中
        return;
      }
      let lifeIdJsonArray = "";
      if (this.listkf.length === 0) {
        lifeIdJsonArray = [];
      }
      // 编码
      lifeIdJsonArray = encodeURI(JSON.stringify(this.listkf));
      //参数：checkedCities（选中的第三方列表）、customerServiceFaq（小程序客服问题词条列表）
      const updateThatsLifeVo = {
        tbThirdPartySmallRoutineType: this.checkedCities,
        customerServiceFaq: lifeIdJsonArray
      }
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/serveExecute",
          updateThatsLifeVo
      );
      if (200 === res.code) {
        this.$message.success("「小程序客服」配置成功");
      } else {
        this.$message.error("「小程序客服」配置失败:" + res.message);
      }
      this.loadingkf = false; //关闭加载中
    },
    // 获取客服常见问题的全部词条选项
    async	initiailize_data(){
      let result = await	axios({
        url:this.$httpUrl+'tb-third-party-wang-customer-service-faq/getAllTitle',
      })
      this.faq_picker.options = result.data.message;
      // console.log("所有词条选项", this.faq_picker.options);
    },
    //添加一行数据
    addkf(){
      if(this.listkf_max_length == this.listkf.length)	{	//如果长度超过了，就不要再添加了
        this.$message.error("超过长度了,无法再添加");
      }else if(this.faq_picker.options.length == 0){
        this.$message.error("请先填写客服词条再添加数据");
      }else {
        this.listkf.push({id:this.faq_picker.options[0].id})
      }
    },
    //排序的名次上升，指排序的下标上升
    row_upkf(index){
      let temp = this.listkf[index] ;
      this.$set(this.listkf,index,this.listkf[index-1]);
      this.$set(this.listkf,index-1,temp);
    },
    //排序的名次下降，指排序的下标下降
    row_downkf(index){
      let temp = this.listkf[index] ;
      this.$set(this.listkf,index,this.listkf[index+1]);
      this.$set(this.listkf,index+1,temp);
    },
    //删除一行的数据
    delete_rowkf(index){
      this.listkf.splice(index,1);
    },
    //清空所有的数据
    clearkf(){
      this.listkf =[];
    },
  },
  computed:{

  },
  mounted() {
    this.getServeExecute();
    this.initiailize_data();
  }
}

</script>


<style scoped lang="less">
.xcxkf{
  margin-top: 20px;
}
.buttonUseDeploy{
  margin-top: 20px;
}
</style>