<template>
  <div>
    <jump_object_parse
        :jump_object_json.sync="updateForm.homeSwiperJson"
        prefix_path="redpacket/small_mall_1/swiper"
        content_title="顶部轮播图">
    </jump_object_parse>
    <el-button :type="loading? 'info': 'primary'" icon="el-icon-s-home" plain round
               @click="updateHomeSwiperJson()" class="buttonUseDeploy" :loading="loading">
      {{ loading? '加载中' : '执 行 首 页 配 置' }}
    </el-button>
  </div>
</template>

<script>
import jump_object_parse
  from "../../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/jump_object_parse.vue";

export default {
  components: {
    jump_object_parse
  },
  props: [
    'checkedCities'
  ],
  data() {
    return {
      //需要的数据字段
      updateForm: {
        tbThirdPartySmallRoutineType: [],
        homeSwiperJson: "",
      },
      loading: false,
    }
  },
  methods: {
    // 查询上一次数据接口
    async getHomeSwiperJson() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getHomeSwiperJson",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        this.updateForm.homeSwiperJson = res.message;
      }
    },
    // 更新数据接口
    async updateHomeSwiperJson() {
      this.loading = true; //开启加载中
      if (this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loading = false; //关闭加载中
        return;
      }
      this.updateForm.tbThirdPartySmallRoutineType = this.checkedCities;
      // console.log("传递的数据：", this.updateForm)
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateHomeSwiperJson",
          this.updateForm
      );
      if (200 === res.code) {
        this.$message.success("「首页」配置成功");
      } else {
        this.$message.error("「首页」配置失败:" + res.message);
      }
      this.loading = false; //关闭加载中
    },
  },
  computed:{

  },
  mounted() {
    this.getHomeSwiperJson()
  }
}
</script>


<style scoped lang="less">
.buttonUseDeploy{
  margin-top: 20px;
}
</style>