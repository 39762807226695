<template>
  <div>
    <jump_object_parse
        :jump_object_json.sync="updateForm.accountBookSwiperJson"
        prefix_path="redpacket/accountBook/swiper"
        content_title="底部轮播图"
    ></jump_object_parse>
    <el-button :type="loading? 'info': 'primary'" icon="el-icon-edit-outline" plain round
               @click="updateAccountBookSwiperJson()" class="buttonUseDeploy" :loading="loading">
      {{ loading? '加载中' : '执 行 记 账 本 配 置' }}
    </el-button>
  </div>
</template>

<script>
import jump_object_parse
  from "../../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/jump_object_parse.vue";

export default {
  components: {
    jump_object_parse
  },
  props: [
    'checkedCities'
  ],
  data() {
    return {
      //需要的数据字段
      updateForm: {
        tbThirdPartySmallRoutineType: [],
        accountBookSwiperJson: "",
      },
      loading: false,
    }
  },
  methods: {
    // 查询上一次数据接口
    async getAccountBookSwiperJson() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getAccountBookSwiperJson",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        this.updateForm.accountBookSwiperJson = res.message;
      }
    },
    // 更新数据接口
    async updateAccountBookSwiperJson() {
      this.loading = true; //开启加载中
      if (this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loading = false; //关闭加载中
        return;
      }
      this.updateForm.tbThirdPartySmallRoutineType = this.checkedCities;
      // console.log("传递的数据：", this.updateForm)
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateAccountBookSwiperJson",
          this.updateForm
      );
      if (200 === res.code) {
        this.$message.success("「记账本」配置成功");
      } else {
        this.$message.error("「记账本」配置失败:" + res.message);
      }
      this.loading = false; //关闭加载中
    },
  },
  computed:{

  },
  mounted() {
    this.getAccountBookSwiperJson()
  }
}
</script>


<style scoped lang="less">
.buttonUseDeploy{
  margin-top: 20px;
}
</style>