<template>
  <div>
    <div class="switch">
      <span class="text">是否展示生活号：</span>
      <el-switch
          v-model="isShowLifeId"
          active-color="#13ce66"
          inactive-color="#ff4949"
          inactive-text="关"
          active-text="开">
      </el-switch>
    </div>
    <div>
      <span class="text">生活号列表：</span>
      <el-button type="primary" @click="add" class="buttonAddClear">添加</el-button>
      <el-button type="primary" @click="clear" class="buttonAddClear">清空</el-button>
      <el-table	:data="list">
        <el-table-column  label="id号"  width="400">
          <template slot-scope="scope">
            <el-input v-model="scope.row.id" placeholder="id号"	clearable></el-input>
          </template>
        </el-table-column>
        <el-table-column  label="排序"	width="150">
          <template slot-scope="scope"	>
            <el-button type="primary" @click="row_up(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>
            <el-button type="primary" @click="row_down(scope.$index)" :disabled="scope.$index==list.length-1"	size="mini">向下</el-button>
          </template>
        </el-table-column>
        <el-table-column  label="操作"  width="80">
          <template slot-scope="scope"	>
            <el-button type="primary" @click="delete_row(scope.$index)" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-button :type="loading? 'info': 'primary'" icon="el-icon-phone" plain round
               @click="useDeploy()" class="buttonUseDeploy" :loading="loading">
      {{ loading? '加载中' : '执 行 生 活 号 配 置' }}
    </el-button>
  </div>
</template>

<script>
export default {
  props: [
    'checkedCities'
  ],
  data() {
    return {
      isShowLifeId: false,
      list:[],
      list_max_length: 5,
      loading: false,
    }
  },
  methods: {
    // 查询上一次数据接口
    async getThatsLife() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getThatsLife",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        this.isShowLifeId = res.message.isShowLifeId;
        if (JSON.parse(decodeURI(res.message.lifeIdJsonArray)) == null) {
          this.list = [];
        } else {
          // 解码
          this.list = JSON.parse(decodeURI(res.message.lifeIdJsonArray));
        }
      }
    },
    // 更新数据接口
    async useDeploy() {
      this.loading = true; //开启加载中
      if (this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loading = false; //关闭加载中
        return;
      }
      // console.log("this.list", this.list)
      let lifeIdJsonArray = "";
      if (!this.isShowLifeId || this.list.length === 0) {
        lifeIdJsonArray = [];
      }
      // 编码
      lifeIdJsonArray = encodeURI(JSON.stringify(this.list));
      // 解码
      // console.log("解码", JSON.parse(decodeURI(lifeIdJsonArray)));

      //参数：checkedCities（选中的第三方列表）、isShowLifeId（是否展示 false->0）、lifeIdJsonArray（生活号列表）
      const updateThatsLifeVo = {
        tbThirdPartySmallRoutineType: this.checkedCities,
        isShowLifeId: this.isShowLifeId,
        lifeIdJsonArray: lifeIdJsonArray
      }
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/ThatsLife",
          updateThatsLifeVo
      );
      if (200 === res.code) {
        this.$message.success("「生活号」配置成功");
      } else {
        this.$message.error("「生活号」配置失败:" + res.message);
      }
      this.loading = false; //关闭加载中
    },
    //添加一行数据
    add(){
      if(this.list_max_length == this.list.length)	{	//如果长度超过了，就不要再添加了
        this.$message.error("超过长度了,无法再添加");
      }else{
        this.list.push({sort:this.sort_default})	//添加
      }
    },
    //清空所有的数据
    clear(){
      this.list = [];
    },
    //排序的名次上升，指排序的下标上升
    row_up(index){
      let temp = this.list[index] ;
      this.$set(this.list,index,this.list[index-1]);
      this.$set(this.list,index-1,temp);
    },
    //排序的名次下降，指排序的下标下降
    row_down(index){
      let temp = this.list[index] ;
      this.$set(this.list,index,this.list[index+1]);
      this.$set(this.list,index+1,temp);
    },
    //删除一行的数据
    delete_row(index){
      this.list.splice(index,1);
    },
  },
  computed:{
    //求出新的一行的排序值
    sort_default(){
      if(this.list.length == 0){
        return 0;
      }else{
        let max = this.list[0].sort ;
        this.list.forEach(	(item)=>{		if(item.sort>max)	max=item.sort;	}	)
        return max+1;
      }
    },
  },
  mounted() {
    this.getThatsLife();
  }
}

</script>

<style scoped lang="less">
.switch{
  margin-bottom: 20px;
}
.buttonAddClear{
  margin-bottom: 20px;
}
.buttonUseDeploy{
  margin-top: 20px;
}
.xcxkf{
  margin-top: 20px;
}
</style>