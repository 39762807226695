<template>
  <div>
    <jump_object_parse
        :jump_object_json.sync="updateForm.indexSwiperJson"
        prefix_path="redpacket/swiper/swiper"
        content_title="整个轮播图"
    ></jump_object_parse>
    <el-button :type="loading? 'info': 'primary'" icon="el-icon-refresh" plain round
               @click="updateIndexSwiperJson()" class="buttonUseDeploy" :loading="loading">
      {{ loading? '加载中' : '执 行 只 有 轮 播 图 的 首 页 配 置' }}
    </el-button>
  </div>
</template>

<script>
import jump_object_parse
  from "../../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/jump_object_parse.vue";

export default {
  components: {
    jump_object_parse
  },
  props: [
    'checkedCities'
  ],
  data() {
    return {
      //需要的数据字段
      updateForm: {
        tbThirdPartySmallRoutineType: [],
        indexSwiperJson: "",
      },
      loading: false,
    }
  },
  methods: {
    // 查询上一次数据接口
    async getIndexSwiperJson() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getIndexSwiperJson",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        this.updateForm.indexSwiperJson = res.message;
      }
    },
    // 更新数据接口
    async updateIndexSwiperJson() {
      this.loading = true; //开启加载中
      if (this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loading = false; //关闭加载中
        return;
      }
      this.updateForm.tbThirdPartySmallRoutineType = this.checkedCities;
      // console.log("传递的数据：", this.updateForm)
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateIndexSwiperJson",
          this.updateForm
      );
      if (200 === res.code) {
        this.$message.success("「只有轮播图的首页」配置成功");
      } else {
        this.$message.error("「只有轮播图的首页」配置失败:" + res.message);
      }
      this.loading = false; //关闭加载中
    },
  },
  computed:{

  },
  mounted() {
    this.getIndexSwiperJson()
  }
}
</script>


<style scoped lang="less">
.buttonUseDeploy{
  margin-top: 20px;
}
</style>