<template>
  <div>
    <el-form :model="updateForm" label-width="auto">
      <el-form-item label="商城顶部轮播图" prop="mallIndexSwiperUrl">
        <image_choose
            :image_url.sync="updateForm.mallIndexSwiperUrl"
            prefix_path="redpacket/mall/indexSwiper"
            is_show_cdn>
        </image_choose>
      </el-form-item>
      <el-form-item label="是否展示菜单按钮列表" prop="isShowMallMenuList">
        <el-switch v-model="updateForm.isShowMallMenuList"></el-switch>
      </el-form-item>
      <el-form-item label="是否展示领取表单按钮" prop="isShowMallForm">
        <el-switch v-model="updateForm.isShowMallForm"></el-switch>
      </el-form-item>
      <el-form-item label="领取表单的标题" prop="mallFormTitle">
        <el-input v-model="updateForm.mallFormTitle" placeholder="请输入领取表单的标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示口令红包" prop="isShowMallPwdRedpacket">
        <el-switch v-model="updateForm.isShowMallPwdRedpacket"></el-switch>
      </el-form-item>
      <el-form-item label="是否展示加入粉丝群" prop="isShowMallFanGroup">
        <el-switch v-model="updateForm.isShowMallFanGroup"></el-switch>
      </el-form-item>
      <el-form-item label="商品当前价格说明" prop="mallGoodsPriceDescCurrent">
        <el-input v-model="updateForm.mallGoodsPriceDescCurrent" placeholder="请输入商品当前价格说明(未被划线的商品价格前的文字)" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示商品的原价" prop="isShowMallGoodsPriceOrigin">
        <el-switch v-model="updateForm.isShowMallGoodsPriceOrigin"></el-switch>
      </el-form-item>
      <el-form-item label="商品原始价格说明" prop="mallGoodsPriceDescOrigin">
        <el-input v-model="updateForm.mallGoodsPriceDescOrigin" placeholder="请输入商品原始价格说明(划线的商品价格前的文字)" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示商品的购买量" prop="isShowMallGoodsSaleCnt">
        <el-switch v-model="updateForm.isShowMallGoodsSaleCnt"></el-switch>
      </el-form-item>
      <el-form-item label="商城首页要展示的商品列表" prop="mallIndexGoodsList">
        <mall_goods_list	:input_list.sync="updateForm.mallIndexGoodsList"></mall_goods_list>
      </el-form-item>
      <el-form-item label="商城首页轮播图条轮的h5路径">
        <el-input v-model="updateForm.mallIndexSwiperH5Url" placeholder="请输入跳转h5链接"></el-input>
      </el-form-item>
      <el-form-item label="商城首页轮播图条轮的h5路径开关">
        <el-switch v-model="updateForm.mallIsSwiperH5Url" active-color="#13ce66" style="display: flex; margin-top: 9px;"></el-switch>
      </el-form-item>
    </el-form>

    <el-button :type="loading? 'info': 'primary'" icon="el-icon-office-building" plain round
               @click="updateShoppingMall()" class="buttonUseDeploy" :loading="loading">
      {{ loading? '加载中' : '执 行 商 城 配 置' }}
    </el-button>
  </div>
</template>

<script>
import image_choose from "../../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/image_choose.vue";
import mall_goods_list from "../../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/mall_goods_list.vue";

export default {
  components: {
    mall_goods_list,
    image_choose
  },
  props: [
    'checkedCities'
  ],
  data() {
    return {
      //需要的数据字段
      updateForm: {
        tbThirdPartySmallRoutineType: [],
        mallIndexSwiperUrl: "",
        isShowMallMenuList: false,
        isShowMallForm: false,
        mallFormTitle: "",
        isShowMallPwdRedpacket: false,
        isShowMallFanGroup: false,
        mallGoodsPriceDescCurrent: "",
        isShowMallGoodsPriceOrigin: false,
        mallGoodsPriceDescOrigin: "",
        isShowMallGoodsSaleCnt: false,
        mallIndexGoodsList: "[{}]",
        mallIndexSwiperH5Url: "",
        mallIsSwiperH5Url: false,
      },
      loading: false,
    }
  },
  methods: {
    // 查询上一次数据接口
    async getShoppingmall() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getShoppingmall",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        this.updateForm.mallIndexSwiperUrl = res.message.mallIndexSwiperUrl;
        this.updateForm.isShowMallMenuList = res.message.isShowMallMenuList;
        this.updateForm.isShowMallForm = res.message.isShowMallForm;
        this.updateForm.mallFormTitle = res.message.mallFormTitle;
        this.updateForm.isShowMallPwdRedpacket = res.message.isShowMallPwdRedpacket;
        this.updateForm.isShowMallFanGroup = res.message.isShowMallFanGroup;
        this.updateForm.mallGoodsPriceDescCurrent = res.message.mallGoodsPriceDescCurrent;
        this.updateForm.isShowMallGoodsPriceOrigin = res.message.isShowMallGoodsPriceOrigin;
        this.updateForm.mallGoodsPriceDescOrigin = res.message.mallGoodsPriceDescOrigin;
        this.updateForm.isShowMallGoodsSaleCnt = res.message.isShowMallGoodsSaleCnt;
        this.updateForm.mallIndexGoodsList = res.message.mallIndexGoodsList;
        this.updateForm.mallIndexSwiperH5Url = res.message.mallIndexSwiperH5Url;
        this.updateForm.mallIsSwiperH5Url = res.message.mallIsSwiperH5Url;
      }
    },
    // 更新数据接口
    async updateShoppingMall() {
      this.loading = true; //开启加载中
      if (this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loading = false; //关闭加载中
        return;
      }
      this.updateForm.tbThirdPartySmallRoutineType = this.checkedCities;
      console.log("传递的数据：", this.updateForm)
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateShoppingMall",
          this.updateForm
      );
      if (200 === res.code) {
        this.$message.success("「商城」配置成功");
      } else {
        this.$message.error("「商城」配置失败:" + res.message);
      }
      this.loading = false; //关闭加载中
    },
  },
  computed:{

  },
  mounted() {
    this.getShoppingmall()
  }
}
</script>


<style scoped lang="less">
.buttonUseDeploy{
  margin-top: 20px;
}
</style>