<script>
export default {
  data() {
    return {
      cdnRefreshCtn: 0,
    }
  },
  methods: {
    // 获取 cdn 刷新次数
    async getCdnRefreshCtn() {
      let {data: {message: cdnRefreshCtn}} = await this.$http.get("cdn/urlRefreshQuotaRemain")
      this.cdnRefreshCtn = cdnRefreshCtn
    }
  },
  mounted() {
    this.getCdnRefreshCtn()
  }
}
</script>

<template>
  <div class="cdn-refresh-ctn" @click="getCdnRefreshCtn">{{ cdnRefreshCtn }}/30000</div>
</template>

<style scoped lang="less">
.cdn-refresh-ctn {
  box-sizing: border-box;
  height: 100%;
  padding: 10px;
  background-color: #B8BBBF;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>