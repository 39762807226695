
<template>
  <div>
    <el-divider content-position="left">
      <span class="Span">类别选择</span>
    </el-divider>
    <div>
      <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-button size="medium" type="primary" @click="switchover()" style="margin-left: 30px">{{ icpStatus }}</el-button>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="city in allCategoryList" :label="city" :key="city.id" style="margin-left: 20px; margin-top: 10px; width: 150px">
          {{city.name}}</el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="Span">第三方选择</span>
    </el-divider>
    <div>
      <el-checkbox v-model="checkAllType" @change="handleCheckAllChangeType">全选</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="dimensionArr" @change="handleCheckedCitiesChangeType">
        <el-checkbox v-for="city in tbThirdPartySmallRoutineType" :label="city" :key="city.id" style="margin-left: 20px; margin-top: 10px; width: 150px">
          {{city.name}}</el-checkbox>
      </el-checkbox-group>
    </div>

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量应用配置</span>
    </el-divider>
    <div>
      <el-button type="primary" @click="useDeploy()" :loading="useDeployLoadingA">
        {{ useDeployLoadingA? '加载中' : '执行选择的类别' }}</el-button>
      <el-button type="warning" plain @click="useDeploythirdparty()" :loading="useDeployLoadingA">
        {{ useDeployLoadingA? '加载中' : '执行选择的第三方' }}</el-button>
      <div style="margin-top: 30px">
        <el-progress :text-inside="true" :stroke-width="22" status="success" :percentage="percentage2"></el-progress>
      </div>
    </div>

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量上传版本</span>
    </el-divider>
    <div>
      <el-button type="primary" @click="updateVersions(1)" :loading="useDeployLoadingC">
        {{ useDeployLoadingC? '加载中' : '执行选择的类别' }}</el-button>
      <el-button type="warning" plain @click="updateVersions(2)" :loading="useDeployLoadingC">
        {{ useDeployLoadingC? '加载中' : '执行选择的第三方' }}</el-button>
      <div style="margin-top: 30px; font-size: 20px; color: #71d5a1">
        最新版本：{{ wantVersion }}
      </div>
      <div style="margin-top: 30px">
        <el-progress :text-inside="true" :stroke-width="22" status="success" :percentage="percentage3"></el-progress>
      </div>
    </div>


    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量提交审核</span>
    </el-divider>
    <div>
      <div>
        <el-button type="primary" @click="submit_multi_auditApply(1)" :loading="useDeployLoadingB">
          {{ useDeployLoadingB? '加载中' : '执行选择的类别' }}</el-button>
        <el-button type="warning" plain @click="submit_multi_auditApply(2)" :loading="useDeployLoadingB">
          {{ useDeployLoadingB? '加载中' : '执行选择的第三方' }}</el-button>
      </div>
      <div style="margin-top: 30px">刷新描述，非必选 ------>></div>
      <el-button	@click="optionModule = ''" type="info" style="margin-right: 5px">清除选择</el-button>
      <el-radio-group v-model="optionModule" v-for="(item,index) in optionModuleList" :key="index" >
        <el-radio-button :label="item.groupName" style="margin: 10px"></el-radio-button>
      </el-radio-group>
      <div style="margin-top: 30px">
        <el-progress :text-inside="true" :stroke-width="22" status="success" :percentage="percentage1"></el-progress>
      </div>
    </div>

    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="SpanA">批量驳回退回开发</span>
    </el-divider>
    <div>
      <el-button type="primary" @click="multiAuditedCancel(1)" :loading="useDeployLoadingD">
        {{ useDeployLoadingD? '加载中' : '执行选择的类别' }}</el-button>
      <el-button type="warning" plain @click="multiAuditedCancel(2)" :loading="useDeployLoadingD">
        {{ useDeployLoadingD? '加载中' : '执行选择的第三方' }}</el-button>
      <div style="margin-top: 30px">
        <el-progress :text-inside="true" :stroke-width="22" status="success" :percentage="percentage4"></el-progress>
      </div>
    </div>


    <div class="fg"></div>
    <el-divider content-position="left">
      <span class="Span">执行结果 | 只显示错误提示信息</span>
    </el-divider>
    <div>
      <div style="font-size: 17px">未通过数量：{{ messages.length }}</div>
      <el-table
          :data="messages"
          style="width: 100%">
        <el-table-column  label="结果" width="120">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="!scope.row.isCompletedInstructions">失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="名字"
            width="300">
        </el-table-column>
        <el-table-column
            prop="operateMessage"
            label="描述"
            width="300">
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="messages = []">清 空</el-button>
      </div>
    </div>
  </div>
</template>

<script>
let intervalId1 = null
let intervalId2 = null
let intervalId3 = null
let intervalId4 = null

export default {
  props: [
      'tbThirdPartySmallRoutineType', //所有的第三方
      'appletType', //第三方类型 Type
  ],
  data() {
    return {
      //（选项）类别、第三方
      checkAll: true,
      allCategoryList: [], //显示所有类别
      icpStatus: "已备案",
      checkedCities: [], //选中的类别数据
      checkAllType: false,
      dimensionArr:[], //选中的第三方

      //批量应用配置
      useDeployLoadingA: false,
      appletAmount2: 0,
      percentage2: 1,

      //批量提交审核
      optionModuleList:[], //全部的选项模块
      optionModule: "", //选中的选项模块.groupName
      useDeployLoadingB: false,
      appletAmount1: 0,
      percentage1: 1,

      //批量上传版本（构建版本）
      useDeployLoadingC: false,
      wantVersion: "0.0.0",
      appletAmount3: 0,
      percentage3: 1,

      //批量驳回退回开发
      useDeployLoadingD: false,
      appletAmount4: 0,
      percentage4: 1,

      //通用
      messages:[], //记录执行的结果
    }
  },
  created() {
    // console.log("类型", this.appletType)
    this.getBatchClassServiceList()
    this.dialogVisible = true;
    this.getOptionModule();
    // console.log("选中的第三方", this.dimensionArr)
    // console.log("选中的类别", this.checkedCities)
  },
  methods: {
    /**
     * 类别选择
     */
    //按钮切换：已备案/未备案
    switchover() {
      this.icpStatus = this.icpStatus === "已备案"? "未备案" : "已备案";
      this.getBatchClassServiceList();
    },
    //获取全部的类别（区分已备案和未备案）
    async getBatchClassServiceList() {
      // console.log("this.appletType", this.appletType)
      const { data: res } = await this.$http.get(
          "/tb-third-party-wang-batch-class/getBatchClassServiceList?appletType=" + this.appletType + "&icpStatus=" + this.icpStatus
      )
      if(res.code == 200){
        this.checkedCities = res.message;
        this.allCategoryList = res.message;
      }else{
        this.$message.error("获取类别失败");
      }
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.allCategoryList : [];
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.allCategoryList.length;
    },
    handleCheckAllChangeType(val) {
      this.dimensionArr = val ? this.tbThirdPartySmallRoutineType : [];
    },
    handleCheckedCitiesChangeType(value) {
      let checkedCount = value.length;
      this.checkAllType = checkedCount === this.tbThirdPartySmallRoutineType.length;
    },
    /**
     * 批量应用配置
     */
    //执行选择的多个类别
    async useDeploy() {
      // console.log("选中的类别", this.checkedCities);
      this.useDeployLoadingA = true;
      if (this.checkedCities.length < 1) {
        this.$message.error("请选择类别");
        this.useDeployLoadingA = false
        return
      }
      let batchClassIdList = this.checkedCities.map(city => city.id);
      // console.log("batchClassIdList", batchClassIdList)

      //计算要执行多少小程序
      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: batchClassIdList,
      }
      await this.countAppletAmount2(multiAppletTypeOrBatchClassIdVo);
      //启动进度条
      this.startTaskProgress2();

      const { data: res } = await this.$http.put(
          "/tb-third-party-wang-batch-class/updateBatchClassAppsMultiConfig?batchClassIdList=" + batchClassIdList
      )
      if(res.code == 200){
        this.$message.success("「应用配置」执行完成");
      }else{
        this.$message.error("「应用配置」执行失败");
      }
      //显示结果
      this.messages = res.message;
      console.log("结果", res.message)
      this.useDeployLoadingA = false

      //任务真正的完成了
      this.percentage2 = 100;
      clearInterval(intervalId2); // 所有任务完成后清除定时器

      //触发父组件 -> 更新小程序列表的数据
      this.$emit('getList');
    },
    //执行选择的多个第三方
    async useDeploythirdparty() {
      this.useDeployLoadingA = true;
      // console.log("选中的第三方", this.dimensionArr)
      if (this.dimensionArr.length < 1) {
        this.$message.error("请选择第三方");
        this.useDeployLoadingA = false
        return
      }
      let appletTypeList = this.dimensionArr.map(city => city.id);

      //计算要执行多少小程序
      let multiAppletTypeOrBatchClassIdVo = {
        appletTypeList: appletTypeList,
      }
      await this.countAppletAmount2(multiAppletTypeOrBatchClassIdVo);
      //启动进度条
      this.startTaskProgress2();

      const { data: res } = await this.$http.put(
          "/tb-third-party-wang-batch-class/updateBatchClassAppsMultiConfigThirdparty?appletTypeList=" + appletTypeList
      )
      if(res.code == 200){
        this.$message.success("「应用配置」执行完成");
      }else{
        this.$message.error("「应用配置」执行失败");
      }
      //记录结果
      this.messages = res.message;
      this.useDeployLoadingA = false

      //任务真正的完成了
      this.percentage2 = 100;
      clearInterval(intervalId2); // 所有任务完成后清除定时器

      //触发父组件 -> 更新小程序列表的数据
      this.$emit('getList');
    },
    //获取 计算要执行多少小程序
    async countAppletAmount2(multiAppletTypeOrBatchClassIdVo) {
      //后台计算有多少小程序
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/countAppletAmount",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.appletAmount2 = res.message;
      } else {
        this.$message.error("计算进度条进度失败:" + res.message);
      }
    },
    //进度条
    startTaskProgress2() {
      this.percentage2 = 1;
      intervalId2 = setInterval(() => {
        if (this.percentage2 < 100) {
          this.percentage2 += Math.round(100 / this.appletAmount2);
        } else {
          // 所有任务完成后清除定时器
          clearInterval(intervalId2);
        }
      }, 300); // 每300毫秒更新一次
    },
    /**
     * 批量提交审核
     */
    //获取全部的选项模块
    async getOptionModule() {
      // 执行接口请求
      const { data: res } = await this.$http(
          "/tb-third-party-small-routine-audit-template/listDigest",
      );
      if (res.code == "200") {
        // this.$message.success("获取成功");
        this.optionModuleList = res.message;
      } else {
        this.$message.error(res.message);
      }
      // console.log("全部选项模块this.optionModuleList", this.optionModuleList)
    },
    //执行提交审核
    async submit_multi_auditApply(is) {
      // console.log("选中的选项模块Name：", this.optionModule)
      // console.log("选中的小程序class_apps.select_list", this.class_apps.select_list)
      // console.log("this.class_apps.list", this.class_apps.list)
      this.useDeployLoadingB = true; //按钮进入等待状态

      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (batchClassIdList.length < 1 && appletTypeListList.length < 1) {
        this.$message.error("请选择类别或第三方");
        this.useDeployLoadingB = false; //解除按钮等待状态
        return
      }
      let multiAppletTypeOrBatchClassIdVo = {
        groupName: this.optionModule,
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
      }

      //计算要执行多少小程序
      await this.countAppletAmount1(multiAppletTypeOrBatchClassIdVo);
      //启动进度条
      this.startTaskProgress1();

      const { data: res } = await this.$http.post(
          "/thirdParty2/multiAppletTypeOrBatchClassIdAudit",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.$message.success("「提交审核」执行完成");
      } else {
        this.$message.error("「提交审核」执行失败:" + res.message);
      }
      if (res.message !== null) {
        //记录结果
        this.messages = res.message;
      }

      //任务真正的完成了
      this.percentage1 = 100;
      clearInterval(intervalId1); // 所有任务完成后清除定时器

      this.useDeployLoadingB = false; //解除按钮等待状态

      //触发父组件 -> 更新小程序列表的数据
      this.$emit('getList');
    },
    //获取 计算要执行多少小程序
    async countAppletAmount1(multiAppletTypeOrBatchClassIdVo) {
      //后台计算有多少小程序
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/countAppletAmount",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.appletAmount1 = res.message;
      } else {
        this.$message.error("计算进度条进度失败:" + res.message);
      }
    },
    //进度条
    startTaskProgress1() {
      this.percentage1 = 1;
      intervalId1 = setInterval(() => {
        if (this.percentage1 < 100) {
          this.percentage1 += Math.round(100 / this.appletAmount1);
        } else {
          // 所有任务完成后清除定时器
          clearInterval(intervalId1);
        }
      }, 13000); // 每11秒更新一次
    },
    /**
     * 批量执行上传版本
     */
    //执行上传版本
    async updateVersions(is) {
      this.useDeployLoadingC = true

      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (batchClassIdList.length < 1 && appletTypeListList.length < 1) {
        this.$message.error("请选择类别或第三方");
        this.useDeployLoadingC = false
        return
      }
      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
      }

      //计算要执行多少小程序
      await this.countAppletAmount3(multiAppletTypeOrBatchClassIdVo);
      //启动进度条
      this.startTaskProgress3();

      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/multiAppletTypeOrBatchClassIdUpgrade",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.$message.success("「上传版本」执行完成");
        console.log("上传版本，结果", res.message)
        this.wantVersion = res.message[0].wantVersion;
      } else {
        this.$message.error("「上传版本」执行失败:" + res.message);
      }
      if (res.message !== null) {
        //记录结果
        this.messages = res.message;
      }
      this.useDeployLoadingC = false

      //任务真正的完成了
      this.percentage3 = 100;
      clearInterval(intervalId3); // 所有任务完成后清除定时器

      //触发父组件 -> 更新小程序列表的数据
      this.$emit('getList');
    },
    //获取 计算要执行多少小程序
    async countAppletAmount3(multiAppletTypeOrBatchClassIdVo) {
      //后台计算有多少小程序
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/countAppletAmount",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.appletAmount3 = res.message;
      } else {
        this.$message.error("计算进度条进度失败:" + res.message);
      }
    },
    //进度条
    startTaskProgress3() {
      this.percentage3 = 1;
      intervalId3 = setInterval(() => {
        if (this.percentage3 < 100) {
          this.percentage3 += Math.round(100 / this.appletAmount3);
        } else {
          // 所有任务完成后清除定时器
          clearInterval(intervalId3);
        }
      }, 900); // 每900毫秒更新一次
    },
    /**
     * 批量驳回退回开发
     */
    async multiAuditedCancel(is) {
      this.useDeployLoadingD = true

      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (batchClassIdList.length < 1 && appletTypeListList.length < 1) {
        this.$message.error("请选择类别或第三方");
        this.useDeployLoadingD = false
        return
      }
      // console.log("选中的", appletTypeListList)
      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
      }

      //计算要执行多少小程序
      await this.countAppletAmount4(multiAppletTypeOrBatchClassIdVo);
      //启动进度条
      this.startTaskProgress4();

      const { data: res } = await this.$http.post(
          "/thirdParty2/multiAuditedCancel",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.$message.success("「退回开发」执行完成");
      } else {
        this.$message.error("「退回开发」执行失败:" + res.message);
      }

      this.useDeployLoadingD = false

      //任务真正的完成了
      this.percentage4 = 100;
      clearInterval(intervalId4); // 所有任务完成后清除定时器

      //触发父组件 -> 更新小程序列表的数据
      this.$emit('getList');
    },
    //获取 计算要执行多少小程序
    async countAppletAmount4(multiAppletTypeOrBatchClassIdVo) {
      //后台计算有多少小程序
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/countAppletAmount",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.appletAmount4 = res.message;
      } else {
        this.$message.error("计算进度条进度失败:" + res.message);
      }
    },
    //进度条
    startTaskProgress4() {
      this.percentage4 = 1;
      intervalId4 = setInterval(() => {
        if (this.percentage4 < 100) {
          this.percentage4 += Math.round(100 / this.appletAmount4);
        } else {
          // 所有任务完成后清除定时器
          clearInterval(intervalId4);
        }
      }, 300); // 每300毫秒更新一次
    },
  }
}

</script>

<style scoped lang="less">
.Span{
  color: #ff8b8b;
  font-size: 20px;
  font-weight: bold;
}
.SpanA{
  color: #938bff;
  font-size: 20px;
  font-weight: bold;
}
.fg{
  margin-top: 100px;
}
</style>