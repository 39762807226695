<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'

export default {
    props: {
        // 富文本编辑器的内容
        value: {
            require: true
        },
        // 富文本编辑器的模式 simple / default
        mode: {
            type: String,
            default: 'default'
        },
        // 富文本编辑器的工具栏的配置对象
        toolbarConfig: {
            default: () => {
                return {
                    toolbarKeys: [
                        "headerSelect",
                        "blockquote",
                        "|",
                        "bold",
                        "underline",
                        "italic",
                        {
                            "key": "group-more-style",
                            "title": "更多",
                            "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M204.8 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path><path d=\"M505.6 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path><path d=\"M806.4 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z\"></path></svg>",
                            "menuKeys": [
                                "through",
                                "code",
                                "sup",
                                "sub",
                                "clearStyle"
                            ]
                        },
                        "color",
                        "bgColor",
                        "|",
                        "fontSize",
                        "fontFamily",
                        "lineHeight",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "todo",
                        {
                            "key": "group-justify",
                            "title": "对齐",
                            "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z\"></path></svg>",
                            "menuKeys": [
                                "justifyLeft",
                                "justifyRight",
                                "justifyCenter",
                                "justifyJustify"
                            ]
                        },
                        {
                            "key": "group-indent",
                            "title": "缩进",
                            "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z\"></path></svg>",
                            "menuKeys": [
                                "indent",
                                "delIndent"
                            ]
                        },
                        "|",
                        "emotion",
                        "insertLink",
                        "divider",
                        "|",
                        "undo",
                        "redo"
                    ]
                }
            }
        },
        // 编辑器内容文本默认字体大小
        fontSizeDefault: {
            type: String,
            default: '16px'
        }
    },
    components: {
        Editor, // 富文本编辑器
        Toolbar, // 富文本编辑器的工具栏
    },
    data() {
        return {
            editor: null, // 富文本编辑器对象
            html: '', // 富文本编辑器中的 html 内容
            // 富文本编辑器的工具栏的配置对象
            // toolbarConfig: {},
            // 富文本编辑器的配置对象
            editorConfig: {
                placeholder: '请输入内容...',
                maxLength: 1000,
            },
            // mode: 'default', // 富文本编辑器的模式
            // 不好用
            // 编辑器的默认内容及其样式，必须在编辑器内容为空，html 内容为 ''，才生效
            // 默认字体要设置在 text 文本对象节点中，children 中是一个一个的文本内容节点
            jsonContent: [
                {
                    type: "paragraph",
                    children: [{
                        text: "",
                        fontSize: this.fontSizeDefault,
                    }],
                },
            ],
        }
    },
    methods: {
        // 编辑器创建完毕时的事件的处理函数
        onCreated(editor) {
            // 一定要用 Object.seal() ，否则会报错
            this.editor = Object.seal(editor)
            // 取消编辑器中选中内容时出现的菜单
            this.editor.getConfig().hoverbarKeys = {}
        },
        // 编辑器内容、选区变化时的事件的处理函数
        onChange(editor) {
            // 没有文本内容清空 html
            if (editor.getText().length <= 0) this.html = ''
            this.$emit('input', this.html)
        },
        // 编辑器失去焦点时的事件的处理函数
        // onBlur() {
        //     this.html = `<p style="font-size: ${this.fontSizeDefault}">${this.html}</p>`
        //     this.$emit('input', this.html)
        // }
    },
    watch: {
        value: {
            handler(newVal) {
                if (newVal.length > 0) this.html = newVal
            },
            immediate: true
        }
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        // 组件销毁时，及时销毁编辑器
        editor.destroy()
    }
}
</script>

<template>
    <div class="rich-editor">
        <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <!-- 这样子设置默认字号不好用 -->
        <Editor
            style="height: 300px; overflow-y: hidden;"
            v-model="html"
            :defaultConfig="editorConfig"
            :defaultContent="jsonContent"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="onChange"
        />
    </div>
</template>

<style scoped lang="less">
.rich-editor {
    border: 1px solid #ccc;
    border-radius: 12px;
    overflow: hidden;
}
</style>